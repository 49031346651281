<template>
  <div>MoneyInput</div>
</template>

<script>
export default {
  name: 'MoneyInput',
  components: {},
  data () {
    return {}
  },
  methods: {}
}
</script>

<style scoped>

</style>
